<template>
  <div>
      <div v-if="noticeList.length<=0" class="d-flex1" style="background:#303030;min-height: 820px;">
          <img style="width:150px" src="@/assets/nothing.png">
          <p>Sem Registros</p>
      </div>
      <div v-else class="container" style="background:#303030;min-height: 820px;">
          <div style="height: 10px"></div>
          <div v-if="!isClick">
              <div v-for="(item,index) in noticeList" class="noticeCss" @click="watchContent(item)">
                  <div class="no_1">
                      <img src="@/assets/notice.png" alt="">
                      <div>
                          <div>{{item.noticeTitle}}</div>
                          <div style="color:#777;">{{item.updateTime}}</div>
                      </div>
                  </div>
                  <div style="color:#777;">
                      Lidos <img src="@/assets/right.png" alt="">
                  </div>
              </div>
          </div>
          <div v-else>
              <div class="contentCss">
                  <div class="c1">
                      <img @click="backTitle" src="@/assets/back.png" alt="">
                  </div>
                  <div class="c2">
                      <div class="c2-1" v-html="content"></div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import {getByType} from "@/api";

export default {
  data(){
    return{
      isClick:false,
      noticeList:'',
      content:'',
    }
  },
  mounted() {
    this.getByType1()
  },
  methods:{
    getByType1(){
      getByType(5).then(res=>{
        this.noticeList = res.data
          console.log(this.noticeList)
      })
    },
    watchContent(item){
      this.isClick  = true
      this.content = item.noticeContent
    },
    backTitle(){
      this.isClick  = false
    },
  }
}

</script>


<style scoped lang="scss">
.noticeCss{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #1c1c1c;
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  .no_1{
    display: flex;
    justify-content: center;
    align-items: center;
    img{
      margin:0 5px;
    }
  }
}
.contentCss{
  .c1{
    img{
      cursor: pointer;
    }
  }
  .c2{
    ::v-deep(.c2-1) {
      p {
        padding: 10px;
        text-align: justify;
        img {
          width:100%
        }
        span{
        }
      }
    }
  }
}
.d-flex1{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 420px;
    flex-direction: column;
    background: #303030;
}
</style>
