<template>
  <div class="container bonus">
    <div class="bonus-1">
      <div class="bonus-1-1">
        <button :class="{'bonus-1-1-1':true,'bonus-1-1-1_active':activeNum==0}" @click="changeNum(0)">Criar feedback</button>
        <button :class="{'bonus-1-1-1':true,'bonus-1-1-1_active':activeNum==1}" @click="changeNum(1)">Meu Feedback</button>
      </div>
      <div v-if="activeNum==1" class="bonus-1-2">
        <div class="bonus-1-2-1">
          <span>Pendente</span><span style="color:#feb705;">R$ 0,00</span>
        </div>
        <span class="bonus-1-2-2">Coletar Tudo</span>
      </div>
    </div>
    <div v-if="activeNum==0">
      <div class="bonus-2">
        <div style="height: 10px"></div>
        <label class="bonus-2-1">Conteúdo do Feedback<span class="bonus-2-1-1">(Faça sugestões para melhorias)</span></label>
        <div style="height: 10px"></div>
        <textarea class="bonus-2-2" :disabled="textareaDisabled" v-model="textareaContent" :placeholder="textareaPleaholder" id="" cols="30" rows="6"></textarea>
        <div class="bonus-2-3">{{nowContentLength}}/{{maxContentLength}}</div>
        <div class="bonus-2-4">
          <label class="bonus-2-1">As imagens não mentem<span class="bonus-2-1-1">(Mais fácil de ser aceito)</span></label>
         <div class="bonus-2-4-1" >
           <van-uploader multiple :max-count="1" v-model="fileList" :max-size="500 * 1024" @oversize="onOversize" />
<!--                   <span @click="logFile">12345</span>-->
<!--           <img src="fileList[0].content" alt="">-->
           <span class="bonus-2-4-1-1">O envio de imagens e vídeos é suportado (com até 50MB)</span>
         </div>

        </div>
        <div class="bonus-2-5">
          <label>Regras para recompensas</label>
          <div class="bonus-2-5-1">
            Estabelecemos bônus substanciais especificamente para coletar feedback, a fim de otimizar o sistema e recursos para proporcionar uma melhor experiência a você! Uma vez aceitas, as recompensas serão concedidas com base na utilidade (exceto aquelas não aceitas).
          </div>
        </div>
      </div>
      <div class="bonus-3">
        <div class="bonus-3-1">Enviar feedback</div>
      </div>
    </div>
    <div v-if="activeNum==1">
      <div class="bonus-4" style="">
        <img style="" src="@/assets/img_none.png" alt="">
        <p>Sem feedback</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      activeNum:0,
      textareaPleaholder:'Suas opiniões são valiosas para nós. Qualquer sugestão valiosa será considerada, e uma vez adotada, o nível de importância determinará a recompensa em dinheiro. Sinta-se à vontade para fazer sugestões!',
      textareaContent:'',
      maxContentLength:'200',
      fileList:[],
    }
  },
  computed: {
    nowContentLength() {
      return this.textareaContent.length;
    },
    textareaDisabled() {
      return this.nowContentLength >= this.maxContentLength;
    }
  },
  watch: {
    textareaContent(newValue) {
      if (newValue.length > this.maxContentLength) {
        this.textareaContent = newValue.substring(0, this.maxContentLength);
      }
    }
  },
  mounted() {
  },
  methods:{
    changeNum(num){
      this.activeNum = num
    },
    onOversize(file) {
      console.log(file);
      Toast('O tamanho do arquivo não pode exceder 50 MB');
    },
    logFile(){
      // 上传图片的信息 base64格式
      console.log(this.fileList[0].content)
    }
  }
}
</script>

<style scoped lang="scss">
.bonus{
  background: #303030;
  min-height: 810px;
  .bonus-1{
    display: flex;
    justify-content: space-between;
    .bonus-1-1{
      .bonus-1-1-1{
        background: #1c1c1c;
        border-radius: 20px;
        font-size: 12px;
        width: 80px;
        margin:10px 0px 10px 10px;
      }
      .bonus-1-1-1_active{
        background: #feb705;
        transition: all 1s ease;
        color: #000;
      }
    }
    .bonus-1-2{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right:10px;
      .bonus-1-2-1{
        margin-right:10px;
        display: flex;
        flex-direction: column;
        font-size: 12px;
        text-align: center;
      }
      .bonus-1-2-2{
        width: 60px;
        height: 35px;
        font-size: 12px;
        background: #ccc;
        color: #fff;
        border-radius: 10px;
        text-align: center;
      }

    }
  }
  .bonus-2{
    background: #1c1c1c;
    width: 95%;
    margin: 10px;
    border-radius: 10px;
    .bonus-2-1{
      color: #fff;
      font-size: 12px;
      padding: 10px;
      .bonus-2-1-1{

      }
    }
    .bonus-2-2{
      border-radius: 10px;
      width: 95%;
      margin:0 10px;
      background: #1c1c1c;
    }
    .bonus-2-3{
      width: 100%;
      text-align: right;
      padding-right: 10px;
      padding-bottom: 10px;
    }
    .bonus-2-4{
      .bonus-2-4-1{
        display: flex;
        flex-direction: column;
        padding: 10px;
        .bonus-2-4-1-1{
          font-size: 12px;
        }
      }
      ::v-deep(.van-uploader__upload){
        border-radius: 10px;
        border: 1px solid #ccc;
        background: #ffffff00;
      }
    }
    .bonus-2-5{
      label{
        padding: 10px;
        color: #fff;
      }
      .bonus-2-5-1{
        text-align: justify;
        padding: 10px;
      }
    }
  }
  .bonus-3{
    //background: #1c1c1c;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    .bonus-3-1{
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #feb705;
      width: 100%;
      color: #000;
      padding: 10px;
      margin: 10px;
      border-radius: 10px;
    }
  }
  .bonus-4{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 500px;
    img{
      width: 105px;height: 105px
    }
  }
}
</style>
