<template>
  <div class="suport">
    <div class="infobox">
      <div class="avatar">
        <img src="@/assets/images/home/img_kf_kf01.png" />
      </div>
      <div class="info">
        <div class="title">Apoio online 24/7</div>
        <div class="desc">
          Converse com o serviço profissional de apoio ao cliente online para
          resolver os seus problemas
        </div>
        <div class="buttons">
          <button class="btn" @click="toApoio">Servicioen línea ①</button>
<!--          <button class="btn" @click="toApoio">Servicioen línea ②</button>-->
<!--          <button class="btn">telegram</button>-->
        </div>
      </div>
    </div>
    <div class="tabs2">
      <van-tabs v-model="active2" animated swipe-threshold="2">
        <van-tab v-for="(tt, i) in tabs2" :title="tt" :key="i">
          <template #title>
            <div class="cus-title">
              <img src="@/assets/images/home/TG.png" v-if="tt == tabs2[0]" />
              <img src="@/assets/images/home/FB.png" v-if="tt == tabs2[1]" />
              <img src="@/assets/images/home/WS.png" v-if="tt == tabs2[2]" />
              <span
                :style="{
                  color: active2 == i ? '#f7b500' : '#e1e1e1',
                  fontSize: '.13rem',
                }"
                >{{ tt }}
              </span>
            </div>
          </template>
          <div class="t1" v-if="tt == tabs2[0]">
            <div class="t1Item active">
              <img src="@/assets/images/home/TG.png" />
              <div class="t1Info">
                <div style="font-size: 0.12rem; color: #777;">
                  Servicio en línea
                </div>
                <div style="font-size: 0.12rem; color: #e3e3e3;">
                    {{TelegramHref01}}
                </div>
              </div>
              <button class="btn" @click="toFly">Contactar agora</button>
            </div>
            <div class="t1Item">
              <img src="@/assets/images/home/TG.png" />
              <div class="t1Info">
                <div style="font-size: 0.12rem; color: #777;">
                  Canal Oficial
                </div>
                <div style="font-size: 0.12rem; color: #e3e3e3;">
                    {{TelegramHref02}}
                </div>
              </div>
              <button class="btn" @click="toFly666">Contactar agora</button>
            </div>
          </div>
          <div class="t1" v-if="tt == tabs2[1]">
            <div class="t1Item active">
              <img src="@/assets/images/home/FB.png" />
              <div class="t1Info">
                <div style="font-size: 0.12rem; color: #777;">
                  Canal Oficial
                </div>
                <div style="font-size: 0.12rem; color: #e3e3e3;">
                    {{FacebookHref}}
                </div>
              </div>
              <button class="btn" @click="toFacebook">Contactar agora</button>
            </div>
          </div>
          <div class="t1" v-if="tt == tabs2[2]">
            <div class="t1Item active">
              <img src="@/assets/images/home/INS.png" />
              <div class="t1Info">
                <div style="font-size: 0.12rem; color: #777;">
                  Canal Oficial
                </div>
                <div style="font-size: 0.12rem; color: #e3e3e3;">
                    {{OutrosHref01}}
                </div>
              </div>
              <button class="btn" @click="toClient01">Contactar agora</button>
            </div>
            <div class="t1Item">
              <img src="@/assets/images/home/X-fill.png" />
              <div class="t1Info">
                <div style="font-size: 0.12rem; color: #777;">
                  Canal Oficial
                </div>
                <div style="font-size: 0.12rem; color: #e3e3e3;">
                    {{OutrosHref02}}
                </div>
              </div>
              <button class="btn" @click="toClient02">Contactar agora</button>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
    <div class="tabs3">
      <van-tabs v-model="active3" animated>
        <van-tab v-for="(t, i1) in tabs3" :title="t" :key="i1">
          <div v-if="i1 === 0">
            <van-collapse v-model="activeNames">
              <van-collapse-item name="1">
                <template #title>
                  <div class="ttitle">
                    <img
                      src="@/assets/images/home/other_wt_icon.png"
                      alt=""
                      class="icon"
                    />
                    <span>Outras perguntas</span>
                  </div>
                </template>
                <div class="question">
                  <div class="item">1、Como é calculado o desempenho?</div>
                  <div class="item">
                    2、Onde posso verificar meu nível de desempenho e comissão?
                  </div>
                  <div class="item">
                    3、Onde posso compartilhar o código de convite?
                  </div>
                  <div class="item">4、Como se tornar um agente?</div>
                </div>
              </van-collapse-item>
              <van-collapse-item name="2">
                <template #title>
                  <div class="ttitle">
                    <img
                      src="@/assets/images/home/withdraw_wt_icon.png"
                      alt=""
                      class="icon"
                    />
                    <span>Perguntas sobre coleção</span>
                  </div>
                </template>
                <div class="question">
                  <div class="item">1、Como é calculado o desempenho?</div>
                </div>
              </van-collapse-item>
              <van-collapse-item name="3">
                <template #title>
                  <div class="ttitle">
                    <img
                      src="@/assets/images/home/pay_wt_icon.png"
                      alt=""
                      class="icon"
                    />
                    <span>Perguntas de recarga</span>
                  </div>
                </template>
                <div class="question">
                  <div class="item">1、Como é calculado o desempenho?</div>
                </div>
              </van-collapse-item>
            </van-collapse>
          </div>
          <div v-else class="tab3-tab2">
            <div class="question">
              <div class="item">1、Como é calculado o desempenho?</div>
              <div class="item">
                2、Onde posso verificar meu nível de desempenho e comissão?
              </div>
              <div class="item">
                3、Onde posso compartilhar o código de convite?
              </div>
              <div class="item">4、Como se tornar um agente?</div>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
      <van-popup v-model="showPopup" position="top" closeable style="border-radius: 10px">
          <iframe style="width:100%;min-height: 500px" :src="url" frameborder="0"></iframe>
      </van-popup>
  </div>
</template>

<script>
import Suporte from './Suporte.vue'
import { getHref } from '@/api/index'
export default {
  components: { Suporte },
  data() {
    return {
      active: 0,
      active2: 0,
      active3: 0,
      activeNames: [],
      tabs2: [
        'Telegram Suporte',
        'Facebook Suporte',
        'Outros funcionários do serviço de apoio ao cliente',
      ],
      tabs3: ['FAQ', 'Perguntas Freqüentes'],
      webUrl: process.env.VUE_APP_WEB_URL,
      Telegram1:"telegram_suporte_01",
      Telegram2:"telegram_suporte_02",
      Facebook:"facebook_suporte",
      Outros1:"outros_01",
      Outros2:"outros_02",
      toCustomer:"toCustomer",
      TelegramHref01:"",
      TelegramHref02:"",
      FacebookHref:"",
      OutrosHref01:"",
      OutrosHref02:"",
      showPopup:false,
      url:'https://direct.lc.chat/18478233/',
    }
  },
  created() {
      this.getByLabel(this.Telegram1,1);
      this.getByLabel(this.Telegram2,2);
      this.getByLabel(this.Facebook,3);
      this.getByLabel(this.Outros1,4);
      this.getByLabel(this.Outros2,5);
      this.getByLabel(this.toCustomer,6);
  },
    methods: {
    getByLabel(label,name){
       getHref(label).then(res=>{
           if (name==1){
               this.TelegramHref01 = res.msg;
           }
           if (name==2){
               this.TelegramHref02 = res.msg;
           }
           if (name==3){
               this.FacebookHref = res.msg;
           }
           if (name==4){
               this.OutrosHref01 = res.msg;
           }
           if (name==5){
               this.OutrosHref02 = res.msg;
           }
           if (name==6){
               this.url=res.msg;
           }

       })
    },
    onClickLeft() {
      this.$router.go(-1)
    },
    toApoio(){
        location.href =  this.url
        // this.showPopup = true
    },
    toFly(){
        location.href =  this.TelegramHref01
    },
    toFly666(){
        location.href =  this.TelegramHref02
    },
    toFacebook(){
        location.href =  this.FacebookHref
    },
    toClient01(){
        location.href =  this.OutrosHref01
    },
    toClient02(){
        location.href =  this.OutrosHref02
    }
  },
  mounted() {},
}
</script>

<style scoped lang="scss">
.suport {
  height: 88vh;
  overflow-y: auto;
  background: #303030;
}

.ttitle {
  display: flex;
  align-items: flex-start;
  padding: 4px 0;
  align-items: center;

  img {
    width: 36px;
    height: 36px;
  }

  span {
    padding-left: 10px;
  }
}

.t1 {
  padding-top: 10px;
  background: #1c1c1c;
  margin-top: 0.01rem;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  padding-bottom: 6px;

  .t1Item {
    display: flex;
    padding: 0.04rem;
    border-radius: 6px;
    margin: 4px 10px;
    justify-content: space-between;
    align-items: center;

    img {
      width: 40px;
      height: 40px;
      margin: 6px;
    }

    .t1Info {
      margin-left: 10px;
      width: 60%;
    }

    .btn {
      color: #000;
      background: #f7b500;
      padding: 0.02rem 0.04rem;
      font-size: 0.13rem;
      height: 0.38rem;
      line-height: 0.14rem;
    }
  }

  .active {
    background: #303030;
  }
}

.tabs2 {
  margin: 0 auto;
  border-radius: 6px;
}

.btn {
  background: transparent;
  color: #f7b500;
  border: #f7b500 solid 1px;
  border-radius: 6px;
  margin-right: 6px;
  font-size: 0.13rem;
  padding: 0.01rem;
  margin-top: 0.01rem;
  min-width: 1rem;
}

.cus-title {
  display: flex;
  align-items: center;

  img {
    width: 22px;
    height: 22px;
    margin-right: 6px;
  }
}

.infobox {
  padding: 12px;
  background: #1c1c1c;
  display: flex;
  width: 96%;
  margin: 14px auto;
  border-radius: 6px;

  .avatar {
    width: 0.5rem;
    height: 0.5rem;

    img {
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 50%;
    }
  }

  .info {
    margin-left: 0.16rem;
    width: 100%;

    .title {
      font-size: 0.19rem;
      color: #e3e3e3;
      margin-bottom: 4px;
    }

    .desc {
      font-size: 0.13rem;
      line-height: 0.14rem;
      color: #727272;
      margin-bottom: 0.1rem;
    }

    .buttons {
      display: flex;
      flex-wrap: wrap;
    }
  }
}

.tabs3 {
  margin-top: 0.15rem;
  .icon {
    width: 0.35rem;
    height: 0.35rem;
  }
  .question {
    font-size: smaller;
    .item {
      padding: 0.1rem 0;
    }
    .item:not(:last-child) {
      border-bottom: 1px solid var(--grey-border);
    }
  }
  .tab3-tab2 {
    background-color: #1c1c1c;
    padding: 0.2rem;
  }
}

::v-deep(.van-tabs__wrap) {
  width: 96%;
  margin: 0 auto !important;
}
::v-deep(.van-tabs__content) {
  padding: 0 9px;
}
::v-deep(.van-popup--top) {
    width: 90%;
    left: 50%;
    transform: translateX(-50%);
}

::v-deep(.van-popup__close-icon) {
    color: #000000 !important;
    font-size: 30px !important;
    cursor: pointer;
    border-radius: 50%;
    border: 2px solid #000;
}

</style>
