<template>
  <div class="container">
    <van-nav-bar
      title="Centro de mensagens"
      left-arrow
      @click-left="onClickLeft"
      class="nav-bar"
    />
    <van-tabs
      v-model="active"
      animated
      @change="tabChanged"
      :style="tabStyle"
      swipe-threshold
      style="position: sticky;"
    >
      <van-tab v-for="(tab, index) in tabs" :title="tab" :key="index">
        <div v-if="tab == tabs[0]">
          <Suporte></Suporte>
        </div>
        <div v-if="tab == tabs[1]">
          <Noticia></Noticia>
        </div>
        <div v-if="tab == tabs[2]">
          <NotifiCaCao></NotifiCaCao>
        </div>
        <div v-if="tab == tabs[3]">
          <Painel></Painel>
        </div>
        <div v-if="tab == tabs[4]">
          <Bonus></Bonus>
        </div>
      </van-tab>

<!--      <van-tab v-for="(tab, index) in tabs" :title="tab" :key="index">-->
<!--        <div v-if="index == 0">-->
<!--          <Suporte></Suporte>-->
<!--        </div>-->
<!--        <div v-if="index == 1">-->
<!--          <Noticia></Noticia>-->
<!--        </div>-->
<!--        <div v-if="index == 2">-->
<!--          <NotifiCaCao></NotifiCaCao>-->
<!--        </div>-->
<!--        <div v-if="index == 3">-->
<!--          <Painel></Painel>-->
<!--        </div>-->
<!--        <div v-if="index == 4">-->
<!--          <Bonus></Bonus>-->
<!--        </div>-->
<!--      </van-tab>-->
    </van-tabs>
  </div>
</template>

<script>
import Suporte from './Suporte.vue'
import NotifiCaCao from './Notificacao.vue'
import Noticia from './Noticia.vue'
import Painel from './Painel.vue'
import Bonus from './Bonus.vue'
export default {
  components: { Suporte, NotifiCaCao,Noticia,Painel ,Bonus},
  data() {
    return {
      active: 0,
      active2: 0,
      active3: 0,
      activeNames: [],
      urlName: '',
      istrue:false,
      tabs: [
        'Suporte',
        'Notícia',
        'Notificação',
        'Painel Rolante',
        'Bônus de Sugestão',
      ],
      tabs2: [
        'Telegram Atendimento ao Cliente',
        'Facebook Atendimento ao Cliente',
        'Outros funcionários do serviço de apoio ao cliente',
      ],
      tabs3: ['FAQ', 'Perguntas Freqüentes'],
      tabStyle: {
        '--lineWidth': this.getTextWidth('Eventos', '0.14rem') + 'px',
      },
      tabStyle2: {
        '--lineWidth':
          this.getTextWidth('Telegram Atendimento ao Cliente', '0.13rem') +
          'px',
      },
      tabStyle3: {
        '--lineWidth': this.getTextWidth('FAQ', '0.13rem') + 'px',
      },
    }
  },
  mounted() {
    console.log("this.$route.query.active:"+this.$route.query.active)
    this.active = parseInt(this.$route.query.active)
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1)
    },
    getTextWidth(text, font) {
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      context.font = font || '16px Arial'
      // 测量文本宽度
      const metrics = context.measureText(text)
      return metrics.width
    },
    tabChanged(e) {
      this.tabStyle = {
        '--lineWidth': this.getTextWidth(this.tabs[e], '16px') + 10 + 'px',
      }
    },
    tabChanged2(e) {
      this.tabStyle2 = {
        '--lineWidth': this.getTextWidth(this.tabs2[e], '16px') + 50 + 'px',
      }
    },
    tabChanged3(e) {
      this.tabStyle3 = {
        '--lineWidth': this.getTextWidth(this.tabs3[e], '16px') + 10 + 'px',
      }
    },
  },
}
</script>

<style scoped lang="scss">
.scorllTab{
  position: fixed;
  top: 46px;
  left: 0;
}
.suport{
  max-height: 80vh;
}
::v-deep {
  .van-collapse-item--border::after {
    border-top: 1px solid #464646;
  }

  .van-cell {
    background-color: #1c1c1c;
    align-items: center;
  }

  .van-collapse-item__content {
    background-color: #1c1c1c;
  }

  .tabs2 {
    position: relative;
    width: 100%;

    .van-tabs {
      width: 100%;

      .van-tabs__wrap {
        width: 96%;
        margin: 0 auto !important;
      }

      .van-tabs__nav {
        border-radius: 6px;
      }
    }
  }

  .van-nav-bar {
    position: sticky;
    top: 0;
    left: 0;
    background-color: #1c1c1c;
    z-index: 2;
    //height: 0.6rem;
  }

  .van-nav-bar__content {
    //height: .6rem;
  }

  .van-nav-bar__title {
    color: #e3e3e3;
    font-size: 0.2rem;
  }

  .van-icon {
    color: #e3e3e3;
    font-size: 0.18rem;
  }

  .van-tabs__nav {
    background: #1c1c1c;
    position: relative;
  }

  .van-tab--active > .van-tab__text {
    color: #f7b500;
  }

  .van-tabs__line {
    background: #f7b500;
    border-radius: 0px;
    width: var(--lineWidth);
  }

  .van-tab {
    //height: .4rem;
    //line-height: .4rem;
    min-width: 94px;
    font-size: 0.15rem;
    margin: 0;
  }
  .van-tabs__content {
    width: 100%;
    margin: 0 auto;
  }
}
</style>
