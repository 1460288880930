var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container bonus"},[_c('div',{staticClass:"bonus-1"},[_c('div',{staticClass:"bonus-1-1"},[_c('button',{class:{'bonus-1-1-1':true,'bonus-1-1-1_active':_vm.activeNum==0},on:{"click":function($event){return _vm.changeNum(0)}}},[_vm._v("Criar feedback")]),_c('button',{class:{'bonus-1-1-1':true,'bonus-1-1-1_active':_vm.activeNum==1},on:{"click":function($event){return _vm.changeNum(1)}}},[_vm._v("Meu Feedback")])]),(_vm.activeNum==1)?_c('div',{staticClass:"bonus-1-2"},[_vm._m(0),_c('span',{staticClass:"bonus-1-2-2"},[_vm._v("Coletar Tudo")])]):_vm._e()]),(_vm.activeNum==0)?_c('div',[_c('div',{staticClass:"bonus-2"},[_c('div',{staticStyle:{"height":"10px"}}),_vm._m(1),_c('div',{staticStyle:{"height":"10px"}}),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.textareaContent),expression:"textareaContent"}],staticClass:"bonus-2-2",attrs:{"disabled":_vm.textareaDisabled,"placeholder":_vm.textareaPleaholder,"id":"","cols":"30","rows":"6"},domProps:{"value":(_vm.textareaContent)},on:{"input":function($event){if($event.target.composing)return;_vm.textareaContent=$event.target.value}}}),_c('div',{staticClass:"bonus-2-3"},[_vm._v(_vm._s(_vm.nowContentLength)+"/"+_vm._s(_vm.maxContentLength))]),_c('div',{staticClass:"bonus-2-4"},[_vm._m(2),_c('div',{staticClass:"bonus-2-4-1"},[_c('van-uploader',{attrs:{"multiple":"","max-count":1,"max-size":500 * 1024},on:{"oversize":_vm.onOversize},model:{value:(_vm.fileList),callback:function ($$v) {_vm.fileList=$$v},expression:"fileList"}}),_c('span',{staticClass:"bonus-2-4-1-1"},[_vm._v("O envio de imagens e vídeos é suportado (com até 50MB)")])],1)]),_vm._m(3)]),_vm._m(4)]):_vm._e(),(_vm.activeNum==1)?_c('div',[_vm._m(5)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bonus-1-2-1"},[_c('span',[_vm._v("Pendente")]),_c('span',{staticStyle:{"color":"#feb705"}},[_vm._v("R$ 0,00")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"bonus-2-1"},[_vm._v("Conteúdo do Feedback"),_c('span',{staticClass:"bonus-2-1-1"},[_vm._v("(Faça sugestões para melhorias)")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"bonus-2-1"},[_vm._v("As imagens não mentem"),_c('span',{staticClass:"bonus-2-1-1"},[_vm._v("(Mais fácil de ser aceito)")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bonus-2-5"},[_c('label',[_vm._v("Regras para recompensas")]),_c('div',{staticClass:"bonus-2-5-1"},[_vm._v(" Estabelecemos bônus substanciais especificamente para coletar feedback, a fim de otimizar o sistema e recursos para proporcionar uma melhor experiência a você! Uma vez aceitas, as recompensas serão concedidas com base na utilidade (exceto aquelas não aceitas). ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bonus-3"},[_c('div',{staticClass:"bonus-3-1"},[_vm._v("Enviar feedback")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bonus-4"},[_c('img',{attrs:{"src":require("@/assets/img_none.png"),"alt":""}}),_c('p',[_vm._v("Sem feedback")])])
}]

export { render, staticRenderFns }